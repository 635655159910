.employee-modal {
    background-color: #fff;
    width: 90%;
    max-width: 30rem;
}

.modal-form__title {
    margin-bottom: 1.5rem;
}

.employee-form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: .9rem;
}

.employee-form__group:last-of-type {
    margin-bottom: 1.5rem;
}

.employee-form__label {
    margin-bottom: .25rem;
}

.employee-form__input {
    padding: .25rem .5rem;
    font-size: 1rem;
}

.employee-form__submit {
    padding: .75rem 1.75rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .08em;
    border: none;
    cursor: pointer;
    background-color: var(--admin-primary-btn-clr);
    color: #fff;
}