.admin-dashboard {
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
    margin: 2rem auto 4rem auto;
}

.admin-dashboard__title {
    margin-bottom: 2rem;
}

.admin-dashboard__list {
    display: flex;
    gap: 3ch;
    padding-bottom: .5rem;
    margin-bottom: 3rem;
    list-style: none;
    border-bottom: 1px solid #e5e5e5;
}

.admin-dashboard__link {
    position: relative;
    color: inherit;
    text-decoration: none;
}

/* Underline effect for active dashboard link */
.admin-dashboard__link span::after {
    position: absolute;
    content: "";
    bottom: -.5rem;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    opacity: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition:
        opacity 220ms cubic-bezier(0, .5, .5, 1),
        transform 220ms cubic-bezier(0, .5, .5, 1);
}

.admin-dashboard__link.admin-dashboard__link--selected span::after {
    transform: scaleX(1);
    opacity: 1;
}

.admin-dashboard__subtitle {
    font-size: 1.25rem;
    margin-bottom: .5rem;
    color: var(--grey);
}

.admin-dashboard__form {
    max-width: 50rem;
    width: 100%;
}

.admin-dashboard__form__textarea {
    padding: 1rem 1.25rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    background-color: #f7fafc;
    border-color: #e2e8f0;
    font-size: 1rem;
    white-space: pre-wrap;
    width: 100%;
    height: 20rem;
}

.admin-dashboard__form__submit-btn {
    padding: .75rem 1.75rem;
    border: none;
    background-color: var(--admin-primary-btn-clr);
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .08em;
    cursor: pointer;
}

@media screen and (max-width: 26.125em) {
    .admin-dashboard__list {
        flex-direction: column;
    }
}