/* dark bg */
.backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
}

.modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6rem;
    background-color: #fff;
}

.modal__close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    padding: .25rem;
    border: none;
    background-color: inherit;
    cursor: pointer;

    opacity: .65;
    transition: opacity 120ms ease;
}

.modal__close-btn:hover {
    opacity: 1;
}

.modal__close-btn-icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
}

.modal__title {
    margin-bottom: 1.5rem;
    max-width: 47ch;
    text-align: center;
}

.modal__confirm-btn {
    padding: .75rem 2rem;
    color: #fff;
    background-color: #000;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    cursor: pointer;
}

@media screen and (max-width: 32em) {
    .modal {
        padding: 3rem;
    }
}