.admin-appointments__title {
    margin-bottom: 1rem;
}

.appointments-list__card {
    max-width: 55rem;
    width: 100%;
    padding: 2rem 6rem;
    margin-bottom: 2rem;
    background-color: #edf2f7;
    box-shadow: 0 13px 15px -13px rgba(0, 0, 0, .2);
    color: hsl(218, 23%, 20%);
}

.appointments-list__card__info-group-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.appointments-list__card__icon-container {
    margin-bottom: .5rem;
}

.info-circle-icon {
    width: 24px;
    height: 24px;
    stroke: #718096;
}

.appointments-list__card__delete-btn {
    padding: .7rem 3.5rem;
    background-color: hsl(218, 23%, 20%);
    color: #fff;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    cursor: pointer;
}

@media screen and (max-width: 50.25em) {
    .appointments-list__card {
        padding: 2rem 3rem;
    }

    .appointments-list__card__info-group-wrapper {
        flex-direction: column;
        gap: 1.75rem;
    }
}