.shop-staff {
    padding: 4rem 0;
    background-color: #f5f5f5;
}

.shop-staff_title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1.75rem;
}

.shop-staff__employees-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 35rem;
    width: 100%;
    margin: 0 auto;
}

.shop-staff__employee {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shop-staff__employee__fig,
.shop-staff__employee__img {
    border-radius: 50%;
}

.shop-staff__employee__fig {
    display: inline-flex;
    margin-bottom: .75rem;
}

.shop-staff__employee__img {
    box-shadow: 0 4px 7px rgba(0, 0, 0, .2);
}

.shop-staff__employee__name {
    color: var(--grey);
}

.shop-staff__employee__level {
    font-size: .95rem;
}

@media screen and (max-width: 40.5em) {
    .shop-staff__employees-container {
        flex-direction: column;
    }
}