.hero {
    position: relative;
    height: 70vh;
    width: 100%;
}

.hero__img {
    filter: brightness(.6);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 20%;
}

.hero__title-container {
    position: absolute;
    top: 25%;
    right: 18%;
    color: #fff;
}

.hero__title {
    font-size: 2rem;
}

.hero__subtitle {
    margin-bottom: 1.25rem;
}

.hero__cta-info {
    margin-bottom: .5rem;
}

.hero__cta-link {
    display: inline-block;
    text-decoration: none;
    padding: .75rem 2rem;
    color: #000;
    background-color: #fff;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    cursor: pointer;
}

@media screen and (max-width: 29em) {
    .hero__title-container {
        top: 25%;
        left: 10%;
    }
}