.header {
    padding: 2rem 0;
    border-top: .375rem solid #000;
}

.nav {
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo-img {
    width: 12.75rem;
}

.nav__list {
    display: flex;
    align-items: center;
    gap: 2.5ch;
    list-style: none;
}

.nav__link {
    padding: .5rem 0;
    color: inherit;
    text-decoration: none;
}

.btn-link-styling {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

@media screen and (max-width: 36.35em) {
    .nav {
        flex-direction: column;
    }

    .nav__list {
        margin-top: 1rem;
    }
}