.service-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    margin-bottom: 6rem;
}

.service-container:nth-of-type(even) figure {
    order: 2;
}

.service__fig__img {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 7px rgba(0, 0, 0, .175);
    filter: grayscale(1);
}

.service__info__title {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: .5rem;
}

.service__info__price {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--grey);
}

@media screen and (max-width: 36.35em) {
    .service-container {
      grid-template-columns: 1fr;
    }

    .service-container:nth-of-type(even) figure {
        order: -1;
    }
}