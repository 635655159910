.employees-table {
    border-collapse: collapse;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .12);
    width: 75%;
}

.employees-table__header,
.employees-table__body__data {
    text-align: left;
}

.employees-table__body tr:nth-of-type(even) {
    background-color: #edf2f7;
}

.employees-table__header {
    padding: .5rem 3rem .5rem 5rem;
    background-color: #f7fafc;
    font-size: .95rem;
    color: var(--grey);
}

.skill-data {
    text-align: right;
}

.employees-table__body__data {
    padding: 1rem 3rem 1rem 5rem;
}

.employee-action {
    background: none;
    border: none;
    font-size: 1rem;
    color: #2b6cb0;
    cursor: pointer;
}

.employee-action:first-of-type {
    border-right: 1px solid #cbd5e0;
    padding-right: .7rem;
}

.employee-action:last-of-type {
    padding-left: .7rem;
}

.add-employee-btn {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    background: none;
    border: none;
    cursor: pointer;

    opacity: .75;
    transition: opacity 110ms ease;
}

.add-employee-btn:hover,
.add-employee-btn:focus {
    opacity: 1;
}

.plus-icon {
    width: 1.5rem;
    margin-right: .25rem;
    stroke: #2b6cb0;
}

.add-employee-btn__span {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    color: #2b6cb0;
}

@media screen and (max-width: 60em) {
    .employees-table {
        width: 100%;
    }

    .employees-table__header {
        padding: .5rem 1.5rem .5rem 2rem;
    }

    .employees-table__body__data {
        padding: 1rem 1.5rem 1rem 2rem;
    }
}

@media screen and (max-width: 40.25em) {
    .employees-table__header {
        padding: .5rem 1rem .5rem 1.5rem;
    }

    .employees-table__body__data {
        padding: 1rem 1rem 1rem 1.5rem;
    }
}

@media screen and (max-width: 35.1875em) {
    .employees-table__header {
        padding: .25rem .5rem .25rem .5rem;
    }

    .employees-table__body__data {
        font-size: .8rem;
        padding: .35rem .5rem .35rem .5rem;
    }

    .employee-action {
        font-size: .8rem;
    }

    .employee-action:first-of-type {
        padding-right: .25rem;
    }
    
    .employee-action:last-of-type {
        padding-left: .25rem;
    }
}