.shop-description {
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
    margin: 0 auto;
    margin-bottom: 6rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 4rem
}

.shop-description__title {
    line-height: 1;
    margin-bottom: 1rem;
    font-size: var(--page-title-fz);
}

.shop-description__txt {
    white-space: pre-wrap;
}

.shop-description__img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 53em) {
    .shop-description {
        grid-template-columns: 1fr;
    }
}